<template>

  <div>
      <section class='section'>
        <ContactForm/>
      </section>
    <div class="spacer">
    </div>
  </div>

</template> 

<script>

import ContactForm from '/app/src/views/frontpage/components/ContactForm'

export default {
  name: 'Contact',
  components: {ContactForm},
  data: () => {
    return {
    }
  },

  created: function() {
  },
  methods: {
    submitForm: function() {
      console.log('submit')
    }
  }
}
</script>

<style scoped>

  .spacer {
    height: 300px;
  }

</style>